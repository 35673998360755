








import { BootstrapImageProps } from '@movecloser/ui-core'
import { Component } from 'vue-property-decorator'

import { isRelated } from '../../../../services'
import { log } from '../../../../support'

import { AbstractModuleUi } from '../../_abstract'

import { ImageModule } from '../Image.contracts'
import { isUnresolvedLink, ResolvedLink } from '../../../../contracts'

/**
 * Presentational component for the `HeadingModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<ImageModuleUi>({
  name: 'ImageModuleUi',
  components: {
    ImageModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/shared" */
      './Image.ui.presentation.vue'
    )
  }
})
export class ImageModuleUi extends AbstractModuleUi<ImageModule> {
  /**
   * Image from `this.module.content` with all related data already resolved and ready to use.
   */
  public image: BootstrapImageProps | null = null

  public link: ResolvedLink | null = null

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await this._fetchImage()
    await this._fetchLink()
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent && this.image !== null
  }

  /**
   * Fetches actual image from the `RelatedService`.
   */
  private async _fetchImage (): Promise<void> {
    const unresolvedImage = this.data.content.image

    if (!isRelated(unresolvedImage)) {
      return
    }

    try {
      const image = await this.fetchImage(unresolvedImage)

      this.image = {
        ...image,
        alt: this.data.content.alt || image.alt,
        caption: this.data.content.caption || image.caption,
        title: this.data.content.title || image.title
      }
    } catch (error) {
      const message: string =
        'ImageModuleUi._fetchImage(): Failed to fetch the related image from the [RelatedService]!'
      log(message, 'error')
    }
  }

  private async _fetchLink (): Promise<void> {
    const link = this.data.content.link

    console.info(link)
    if (typeof link === 'undefined' || link === null) {
      return
    }

    if (!isUnresolvedLink(link)) {
      this.link = link
      console.info(this.link)
      return
    }

    try {
      this.link = await this.fetchLink(link)
      console.info(this.link)
    } catch (error) {
      const message: string =
        'ImageModuleUi._fetchLink(): Failed to fetch the related link from the [RelatedService]!'
      log(message, 'error')
    }
  }
}

export default ImageModuleUi
